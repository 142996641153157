<template>
  <div>
    <Transition mode="in-out">
      <span style="background-color: red;" class="bubble flex justify-center" v-if="hideRecordingInfo != 'true'"
        @click="hideAndCopy()">Your session is being recorded! Click here to hide and copy info to clipboard. ( {{
          useExpCookieIdentifier() }} | {{ tracker?.sessionID }} )</span>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import { useClipboard, useSessionStorage } from '@vueuse/core';
const hideRecordingInfo = useSessionStorage('hideRecordingInfo', 'false');
const tracker = ref()
let timer: NodeJS.Timeout | undefined = undefined;

onMounted(() => {
  if (useUserGroup()?.value == 'internal') {
    callOnce('startTrackerInternal', async () => {
      timer = setTimeout(() => {
        hideRecordingInfo.value = 'true'
      }, 2500)
      tracker.value = await startTracking()
    })
  }
})

function hideAndCopy() {
  hideRecordingInfo.value = 'true';
  const { text, copy, copied, isSupported } = useClipboard({})
  if (isSupported) {
    let info = {
      trackingId: useExpCookieIdentifier().value,
      trackingSessionId: tracker?.value?.sessionID,
      // window: JSON.stringify(window),
    }
    copy(JSON.stringify(info));
  }
}

onBeforeUnmount(() => {
  if (timer) {
    clearTimeout(timer);
  }
})

</script>
<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 1.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>